define('frontend/models/hasread', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var belongsTo = _emberData.default.belongsTo;
    exports.default = _emberData.default.Model.extend({
        lecturer: belongsTo('lecturer'),
        complaint: belongsTo('complaint')
    });
});