define('frontend/controllers/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    paperToaster: Ember.inject.service(),
    actions: {
      login: function login() {
        var _this = this;

        var _getProperties = this.getProperties('email', 'password'),
            email = _getProperties.email,
            password = _getProperties.password;

        this.get('session').authenticate('authenticator:devise', email, password).catch(function (reason) {
          _this.set('errorMessage', reason.error);
        });
      },
      logout: function logout() {
        this.get('session').invalidate();
      }
    }
  });
});