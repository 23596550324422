define('frontend/components/complaint-form', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    placeholder: "Schreibe eine Nachricht an die Dozierenden",
    paperToaster: Ember.inject.service(),
    store: Ember.inject.service(),
    selectedSemester: Ember.computed('model.semesters.[]', function () {
      return this.get('model.semesters').objectAt(0);
    }),
    actions: {
      searchCourses: function searchCourses(data) {
        var searchstringarray = data.split(" ");
        var result = this.get("store").query('course', {
          filter: {
            coursesearch: searchstringarray,
            semester: this.get('seletedSemester')
          }
        });
        return result;
      },
      clearCourse: function clearCourse() {
        this.set('selectedCourse', null);
      },
      sendComplaint: function sendComplaint() {
        var _this = this;

        this.get('store').createRecord('complaint', {
          message: this.get('message'),
          approved: false,
          reviewed: false,
          course: this.get('selectedCourse')
        }).save().then(function () {
          _this.set('message', '');
          _this.set('selectedCourse', null);
          _this.set('searchText', '');
          _this.get('paperToaster').show("Kummer gespeichert.", {
            duration: 4000
          });
        }).catch(function () {
          _this.get('paperToaster').show("Kummer konnte nicht gespeichert werden.", {
            duration: 4000
          });
        });
      }
    }
  });
});