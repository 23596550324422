define('frontend/controllers/inbox/settings', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    paperToaster: Ember.inject.service(),
    actions: {
      save: function save() {
        var _this = this;

        this.get('model').save().then(function () {
          _this.get('paperToaster').show("Änderungen erfolgreich gespeichert.", {
            duration: 4000
          });
        });
      }
    }
  });
});