define('frontend/helpers/svg-jar', ['exports', 'ember-svg-jar/utils/make-helper', 'ember-svg-jar/utils/make-svg', 'frontend/inline-assets'], function (exports, _makeHelper, _makeSvg, _inlineAssets) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.svgJar = svgJar;
  var htmlSafe = Ember.String.htmlSafe;
  function svgJar(assetId, svgAttrs) {
    return htmlSafe((0, _makeSvg.default)(assetId, svgAttrs, _inlineAssets.default));
  }

  exports.default = (0, _makeHelper.default)(svgJar);
});