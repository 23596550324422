define('frontend/routes/inbox', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    session: service('session'),

    model: function model() {
      return this.store.findAll("complaint");
    },
    actions: {
      logout: function logout() {
        this.get('session').invalidate();
      }
    }
  });
});