define('frontend/controllers/inbox/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    currentUser: Ember.inject.service('current-user'),
    actions: {
      markasread: function markasread(complaint) {
        var record = this.store.createRecord('hasread', { complaint: complaint,
          lecturer: this.get("currentUser.user") });
        record.save().then(function () {
          complaint.unloadRecord();
        });
      }
    }
  });
});