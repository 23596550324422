define('frontend/authenticators/devise', ['exports', 'ember-simple-auth/authenticators/devise'], function (exports, _devise) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _devise.default.extend({
    identificationAttributeName: 'email',
    resourceName: 'lecturer',
    tokenAttributeName: 'authentication_token',
    serverTokenEndpoint: 'lecturers/sign_in'
  });
});