define('frontend/models/lecturer', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany;
  exports.default = _emberData.default.Model.extend({
    salutation: attr('string'),
    surname: attr('string'),
    givenname: attr('string'),
    email: attr('string'),
    wantsreview: attr('boolean'),
    notifications: attr('string'),
    //readcomplaints:hasMany('complaint'),
    //complaints:hasMany('complaint'),
    courses: hasMany('course')
  });
});