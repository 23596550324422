define('frontend/components/lecturer-settings', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    session: Ember.inject.service('session'),
    paperToaster: Ember.inject.service(),
    actions: {
      changePassword: function changePassword() {
        var _this = this;

        return new Ember.RSVP.Promise(function (resolve, reject) {
          var headers = [];
          _this.get("session").authorize("authorizer:devise", function (headerName, headerValue) {
            headers.push({ name: headerName, value: headerValue });
            if (headers.length == 2) Ember.$.ajax("/lecturers/update_password", {
              method: "PATCH",
              contentType: "application/vnd.api+json",
              beforeSend: function beforeSend(xhr) {
                xhr.setRequestHeader(headers[0].name, headers[0].value);
                xhr.setRequestHeader(headers[1].name, headers[1].value);
              },
              success: function success() {
                resolve(true);
              },

              data: JSON.stringify({
                lecturer: {
                  password: _this.get("password"),
                  current_password: _this.get("current_password"),
                  password_confirmation: _this.get("password_confirmation")
                }
              })
            }).fail(function () {
              reject();
            });
          });
        }).then(function () {
          _this.get("paperToaster").show("Passwort erfolgreich geändert.", { timeout: 4000 });
        }).catch(function () {
          _this.get("paperToaster").show("Es ist ein Fehler aufgetreten. Passwörter stimmen nicht überein, sind nicht sicher genug oder das aktuelle ist falsch.", { timeout: 4000 });
        });
      }
    }
  });
});