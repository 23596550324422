define('frontend/adapters/application', ['exports', 'ember-data/adapters/json-api', 'ember-simple-auth/mixins/data-adapter-mixin'], function (exports, _jsonApi, _dataAdapterMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var _Ember$String = Ember.String,
      pluralize = _Ember$String.pluralize,
      underscore = _Ember$String.underscore;
  exports.default = _jsonApi.default.extend(_dataAdapterMixin.default, {
    authorizer: 'authorizer:devise',
    pathForType: function pathForType(type) {
      return pluralize(underscore(type));
    }
  });
});