define('frontend/serializers/complaint', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONAPISerializer.extend({
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);
      delete json.data.attributes['created-at'];
      delete json.data.relationships['lecturers'];
      return json;
    }
  });
});