define('frontend/models/course', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;
  exports.default = _emberData.default.Model.extend({
    complaints: hasMany('complaint'),
    name: attr('string'),
    faculty: belongsTo('faculty'),
    coursetype: belongsTo('coursetype'),
    createdAt: attr('date'),
    lecturers: hasMany('lecturer'),
    semester: belongsTo('semester'),
    lecturernames: attr('string'),
    facultyname: attr('string')
  });
});