define('frontend/routes/inbox/settings', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    currentUser: Ember.inject.service('current-user'),
    model: function model() {
      return this.get('currentUser.user');
    }
  });
});